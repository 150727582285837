// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { NavItemI } from 'core';

import { useParams } from 'react-router-dom';
import I18n from 'i18n-js';
import { CommunityModel } from 'types/models/community';

const createNavigationLinks = (cupsSelected: CommunityModel | null): Array<NavItemI> => {
  const { communityID } = useParams<{ communityID: string }>();
  const { t } = I18n;

  const links: Array<NavItemI> = [
    {
      name: t(`Sidenav.Tecnic.Communities_Detail`),
      url: `/tecnic/communities/${communityID}/detail`,
    },
    // {
    //   name: t(`Sidenav.Tecnic.Communities_Members`),
    //   url: `/tecnic/communities/${communityID}/members`,
    //   admin: true,
    // },
    {
      name: t(`Sidenav.Tecnic.Communities_Enrolled`),
      url: `/tecnic/communities/${communityID}/enrolled`,
    },
    {
      name: t(`Sidenav.Tecnic.Communities_Autoreport`),
      url: `/tecnic/communities/${communityID}/autoreport`,
    },
    {
      name: t(`Sidenav.Tecnic.Communities_Promotores`),
      url: `/tecnic/communities/${communityID}/promotores`,

      hidden: cupsSelected?.promotores.length === 0, // Oculta el elemento si communityID es igual a '0'
    },
    {
      name: t(`Sidenav.Tecnic.Communities_Customization`),

      url: `/tecnic/communities/${communityID}/onboarding`,
      children: [
        {
          name: 'Onboarding',
          url: `/tecnic/communities/${communityID}/onboarding/onboarding`,

          hidden: false,
        },
        {
          name: t('ComunidadesCustomOnboardingProcess.form.horarios'),
          url: `/tecnic/communities/${communityID}/onboarding/horarios`,
        },
        {
          name: t(`ComunidadesCustomOnboardingProcess.form.tipodeContacto`),
          url: `/tecnic/communities/${communityID}/onboarding/contact`,
        },
        {
          name: t(`ComunidadesCustomOnboardingProcess.form.PopUp`),
          url: `/tecnic/communities/${communityID}/onboarding/popup`,
        },
        {
          name: 'Faqs',
          url: `/tecnic/communities/${communityID}/onboarding/faqs`,
        },
        {
          name: 'Informe automatico',
          url: `/tecnic/communities/${communityID}/onboarding/informe-automatico`,
        },
        {
          name: t(`ComunidadesCustomOnboardingProcess.form.ofertaTitle`),
          url: `/tecnic/communities/${communityID}/onboarding/oferta`,
        },
        {
          name: t(`ComunidadesCustomOnboardingProcess.form.confirmacionMensajeTab`),
          url: `/tecnic/communities/${communityID}/onboarding/confirmacionMensaje`,
        },
        {
          name: t(`ComunidadesCustomOnboardingProcess.form.otrosTab`),
          url: `/tecnic/communities/${communityID}/onboarding/otros`,
        },
      ],
    },

    // {
    //   name: t(`Sidenav.Tecnic.Communities_Analytics`),
    //   url: `/tecnic/communities/${communityID}/analytics`,
    //   admin: true,
    // },
  ];
  return links;
};

export default createNavigationLinks;
