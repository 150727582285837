import React from 'react';

interface MiembroRowProps {
  miembro: any;
  metodoDeCalculos: string; // New prop to determine the calculation method
}

const MiembroRow = ({ miembro, metodoDeCalculos }: MiembroRowProps): JSX.Element => (
  <tr key={miembro.id} style={{ textAlign: 'center' }}>
    <td>{miembro.nombre}</td>
    <td>{miembro.apellidos}</td>
    {/* Conditional rendering of Beta columns */}
    {metodoDeCalculos === 'Betas fijas' && <td>-</td>}
    {metodoDeCalculos === 'Betas fijas 3 tipos' && (
      <>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </>
    )}
    <td>{miembro.autoconsumoEuro ? miembro?.autoconsumoEuro.toFixed(2) : 0} €</td>
    <td>{miembro.excedenteEuro ? miembro?.excedenteEuro.toFixed(2) : 0} €</td>
    <td>{miembro.ahorrosEuro ? miembro?.ahorrosEuro.toFixed(2) : 0} €</td>
  </tr>
);

export default MiembroRow;
