import React, { useEffect, useState } from 'react';

import ModalMatcher from 'components/ModalMatcher/ModalMatcher';

import { I18n } from 'core';
import { SelectItemI } from 'components/NewInputs/Select/ElecsumSelect';
import { ConfiguracionMatcherAPI } from 'types/models/ConfiguracionMatcher';
import MatcherConfiguracionMiembrosInstance from 'services/remote/MatcherConfiguracionMiembrosService';

import { CommunityModel } from 'types/models/community';
import { InscritosModel } from 'types/models/inscritos';
import InscritosServicentance from 'services/remote/Inscritos';
import ConfiguracionMiembro from 'types/models/configuracionMiembrosMatcher';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useToggle } from 'hooks/useToogle';
import ButtonCsv from 'components/ButtonCsv/ButtonCsv';
import { SectionSubTitle } from 'components';
import AddMemberPopUp from './AddMemberPopUp';
import AddConfiguracion from './AddConfiguracion';
import styles from '../../AddMatcherPage.module.scss';

interface MatcherConfigPropsI {
  configuracionDetalle: ConfiguracionMatcherAPI;
  onChange: CallableFunction;
}
const MatcherConfig = (props: MatcherConfigPropsI) => {
  const { configuracionDetalle, onChange } = props;
  const configuracionDetalleId = (configuracionDetalle as ConfiguracionMatcherAPI).id;
  const [modifcado, setModificado] = useState('Default');
  const [mensajeError, setMensajeError] = useState({
    codigo: '',
    mensaje: '',
  });
  const [mensajeErrorLoadInscritos, setMensajeErrorLoadInscritos] = useState<
    { codigo: string; status: string; mensaje: string }[]
  >([]);

  const [disabled, setDisabled] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState(false);

  const [show, setShow] = useState(false);
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [isSwitchOnLoadMember, toggleIsSwitchOnLoadMember] = useToggle();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [selectedFileLoadMiembro, setSelectedFileLoadMiembro] = useState<File | null>(null);

  const [selectedPerfilConsumo, setSelectedPerfilConsumo] = useState<SelectItemI>();
  const [selectedInscrito, setSelectedInscrito] = useState<string>();
  const [selectedComunidadDad, setSelectedComunidadDad] = useState<CommunityModel | undefined>(undefined);
  const [communitiesEnrolled, setCommunitiesEnrolled] = useState<InscritosModel[]>([]);
  const [configuracionMiembros, setConfiguracionMiembros] = useState<ConfiguracionMiembro[]>([]);
  const [deleteConfigMiembro, setDeleteConfigMiembro] = useState('Default');
  const [updateConfigMiembro, setUpdateConfigMiembro] = useState('Default');

  const [addPersonalMemberData, setPersonalMemberData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    nif: '',
    cups: '',
    telefono: ' ',
    poblacion: '',
    direccion: '',
    domicilio: '',
    provincia: '',
    codigoPostal: '',
  });
  const [addMember, setMember] = useState({
    preferente: 'no',
    vip: 'no',
    bMin: 0,
    bMax: 0,
  });
  const [consumoAnual, setconsumoAnual] = useState({
    consumoAnual: 0,
  });
  const [isDomestico, setIsDomestico] = useState({
    domestico: 'domestico',
  });
  const [addPotencias, setPotencias] = useState({
    terminoEnergiaP1: 0,
    terminoEnergiaP2: 0,
    terminoEnergiaP3: 0,
    terminoEnergiaP4: 0,
    terminoEnergiaP5: 0,
    terminoEnergiaP6: 0,
    consumPunta: 0,
    consumPla: 0,
    consumVall: 0,
    precioCompensacion: 0,
  });

  const getInitalDate = (): Date => {
    const currentDate = new Date(configuracionDetalle.fechaInicio);

    return currentDate;
  };

  const [date, setDate] = useState({
    day: getInitalDate().getDate(),
    month: getInitalDate().getMonth() + 1,
    year: getInitalDate().getFullYear(),
  });

  const updateDate = (newDate: Date): void => {
    if (!newDate) {
      return;
    }
    setDate({
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    });
  };

  const submitForm = async (): Promise<void> => {
    // Si no hay inscrito, se crea con los datos rellenados en datos personales
    setLoadingForm(true);
    // TODO -> Llamar a POST inscritos con newInscrito

    if (selectedInscrito) {
      // Si se ha seleccionado un inscrito, se le pasa el idInscrito
      const newConfiguracionMiembro = {
        idInscrito: selectedInscrito,
        idPerfil: Number(selectedPerfilConsumo?.id),
        consumoAnual: consumoAnual.consumoAnual,
        betaMax: addMember.bMax,
        betaMin: addMember.bMin,
        miembroPreferente: addMember.preferente,
        miembroVip: addMember.vip,
        seleccionado: 'si',
      };

      const object = {
        id: selectedInscrito,
        nombre: addPersonalMemberData.nombre,
        apellidos: addPersonalMemberData.apellidos,
        email: addPersonalMemberData.email,
        nif: addPersonalMemberData.nif,
        cups: addPersonalMemberData.cups,
        telefono: addPersonalMemberData.telefono,
        poblacion: addPersonalMemberData.poblacion,
        direccionCompleta: addPersonalMemberData.direccion,
        domicilio: addPersonalMemberData.domicilio,
        provincia: addPersonalMemberData.provincia,
        codigoPostal: addPersonalMemberData.codigoPostal,
        tipoConsumo: isDomestico.domestico,
        precioCompensacion: addPotencias.precioCompensacion,
        terminoEnergiaP1: addPotencias.terminoEnergiaP1,
        terminoEnergiaP2: addPotencias.terminoEnergiaP2,
        terminoEnergiaP3: addPotencias.terminoEnergiaP3,
        terminoEnergiaP4: addPotencias.terminoEnergiaP4,
        terminoEnergiaP5: addPotencias.terminoEnergiaP5,
        terminoEnergiaP6: addPotencias.terminoEnergiaP6,
      };
      try {
        await InscritosServicentance.putInscritoMatcher(object as InscritosModel);

        await MatcherConfiguracionMiembrosInstance.postConfiguracionMiembro(
          configuracionDetalleId,
          newConfiguracionMiembro,
        ).then(async (miembroCreado) => {
          if (selectedFile) {
            const addCSV = await MatcherConfiguracionMiembrosInstance.subirCsvConfiguracionMatcherMiembros(
              configuracionDetalleId,
              miembroCreado.datos.id,
              selectedFile,
            );
            if (addCSV === 200) {
              setModificado('ADD');
              setShow(false);
              setMember({
                preferente: 'no',
                vip: 'no',
                bMin: 0,
                bMax: 0,
              });
              setPotencias({
                terminoEnergiaP1: 0,
                terminoEnergiaP2: 0,
                terminoEnergiaP3: 0,
                terminoEnergiaP4: 0,
                terminoEnergiaP5: 0,
                terminoEnergiaP6: 0,
                consumPunta: 0,
                consumPla: 0,
                consumVall: 0,
                precioCompensacion: 0,
              });
              setPersonalMemberData({
                nombre: '',
                cups: '',
                apellidos: '',
                email: '',
                nif: '',
                telefono: ' ',
                poblacion: '',
                direccion: '',
                domicilio: '',
                provincia: '',
                codigoPostal: '',
              });
              setIsDomestico({
                domestico: 'domestico',
              });
              setconsumoAnual({
                consumoAnual: 0,
              });
              setSelectedInscrito(undefined);
              setDisabled(true);
              setSelectedFile(null);
              setLoadingForm(false);
            } else {
              await MatcherConfiguracionMiembrosInstance.deleteConfiguracionMiembro(
                configuracionDetalleId,
                miembroCreado.datos.id,
              );
              setMensajeError(addCSV);
              setLoadingForm(false);
            }
          } else {
            setModificado('ADD');
            setShow(false);
            setMember({
              preferente: 'no',
              vip: 'no',
              bMin: 0,
              bMax: 0,
            });
            setPotencias({
              terminoEnergiaP1: 0,
              terminoEnergiaP2: 0,
              terminoEnergiaP3: 0,
              terminoEnergiaP4: 0,
              terminoEnergiaP5: 0,
              terminoEnergiaP6: 0,
              consumPunta: 0,
              consumPla: 0,
              consumVall: 0,
              precioCompensacion: 0,
            });
            setPersonalMemberData({
              nombre: '',
              apellidos: '',
              email: '',
              nif: '',
              telefono: ' ',
              poblacion: '',
              cups: '',

              direccion: '',
              domicilio: '',
              provincia: '',
              codigoPostal: '',
            });
            setIsDomestico({
              domestico: 'domestico',
            });
            setconsumoAnual({
              consumoAnual: 0,
            });
            setSelectedInscrito(undefined);
            setDisabled(true);
            setSelectedFile(null);
            setLoadingForm(false);
          }
        });
      } catch (error: unknown) {
        let responsePromise;
        if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
          toast.error((error as AxiosError)?.response?.data.errores[0], {
            autoClose: 8000, // Duración de 8 segundos
            className: styles.customToast, // Assign the custom class name
          });
        }
        if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
          responsePromise = {
            codigo: (error as AxiosError).response?.data[0].codigo,
            mensaje: (error as AxiosError).response?.data[0].mensaje,
          };
        }
        toast.error(responsePromise, {
          autoClose: 8000, // Duración de 8 segundos
        });
        setLoadingForm(false);
      }
    } else {
      const newInscrito = {
        idComunidad: configuracionDetalle.idComunidad,
        nombre: addPersonalMemberData.nombre,
        apellidos: addPersonalMemberData.apellidos,
        email: addPersonalMemberData.email,
        nif: addPersonalMemberData.nif,
        cups: addPersonalMemberData.cups,
        telefono: addPersonalMemberData.telefono,
        poblacion: addPersonalMemberData.poblacion,
        direccionCompleta: addPersonalMemberData.direccion,
        domicilio: addPersonalMemberData.domicilio,
        provincia: addPersonalMemberData.provincia,
        codigoPostal: addPersonalMemberData.codigoPostal,
        tipoConsumo: isDomestico.domestico,
        precioCompensacion: addPotencias.precioCompensacion,
        terminoEnergiaP1: addPotencias.terminoEnergiaP1,
        terminoEnergiaP2: addPotencias.terminoEnergiaP2,
        terminoEnergiaP3: addPotencias.terminoEnergiaP3,
        terminoEnergiaP4: addPotencias.terminoEnergiaP4,
        terminoEnergiaP5: addPotencias.terminoEnergiaP5,
        terminoEnergiaP6: addPotencias.terminoEnergiaP6,
      };
      try {
        await InscritosServicentance.addInscrito(newInscrito).then(async (response) => {
          const newConfiguracionMiembro = {
            idInscrito: response.datos.id,
            idPerfil: Number(selectedPerfilConsumo?.id),
            consumoAnual: consumoAnual.consumoAnual,
            betaMax: addMember.bMax,
            betaMin: addMember.bMin,
            miembroPreferente: addMember.preferente,
            miembroVip: addMember.vip,
            seleccionado: 'si',
          };
          try {
            await MatcherConfiguracionMiembrosInstance.postConfiguracionMiembro(
              configuracionDetalleId,
              newConfiguracionMiembro,
            ).then(async (miembroCreado) => {
              if (selectedFile) {
                const addCSV = await MatcherConfiguracionMiembrosInstance.subirCsvConfiguracionMatcherMiembros(
                  configuracionDetalleId,
                  miembroCreado.datos.id,
                  selectedFile,
                );
                if (addCSV === 200) {
                  setModificado('ADD');
                  setMember({
                    preferente: 'no',
                    vip: 'no',
                    bMin: 0,
                    bMax: 0,
                  });
                  setPotencias({
                    terminoEnergiaP1: 0,
                    terminoEnergiaP2: 0,
                    terminoEnergiaP3: 0,
                    terminoEnergiaP4: 0,
                    terminoEnergiaP5: 0,
                    terminoEnergiaP6: 0,
                    consumPunta: 0,
                    consumPla: 0,
                    consumVall: 0,
                    precioCompensacion: 0,
                  });
                  setPersonalMemberData({
                    nombre: '',
                    apellidos: '',
                    email: '',
                    nif: '',
                    telefono: ' ',
                    poblacion: '',
                    direccion: '',
                    domicilio: '',
                    provincia: '',
                    codigoPostal: '',
                    cups: '',
                  });
                  setIsDomestico({
                    domestico: 'domestico',
                  });
                  setconsumoAnual({
                    consumoAnual: 0,
                  });
                  setSelectedInscrito(undefined);
                  setDisabled(true);
                  setSelectedFile(null);
                  setLoadingForm(false);

                  setShow(false);
                } else {
                  await MatcherConfiguracionMiembrosInstance.deleteConfiguracionMiembro(
                    configuracionDetalleId,
                    miembroCreado.datos.id,
                  );
                  setMensajeError(addCSV);
                  setLoadingForm(false);
                }
              } else {
                setModificado('ADD');
                setMember({
                  preferente: 'no',
                  vip: 'no',
                  bMin: 0,
                  bMax: 0,
                });
                setPotencias({
                  terminoEnergiaP1: 0,
                  terminoEnergiaP2: 0,
                  terminoEnergiaP3: 0,
                  terminoEnergiaP4: 0,
                  terminoEnergiaP5: 0,
                  terminoEnergiaP6: 0,
                  consumPunta: 0,
                  consumPla: 0,
                  consumVall: 0,
                  precioCompensacion: 0,
                });
                setPersonalMemberData({
                  nombre: '',
                  apellidos: '',
                  email: '',
                  nif: '',
                  telefono: ' ',
                  poblacion: '',
                  direccion: '',
                  domicilio: '',
                  provincia: '',
                  codigoPostal: '',
                  cups: '',
                });
                setIsDomestico({
                  domestico: 'domestico',
                });
                setconsumoAnual({
                  consumoAnual: 0,
                });
                setSelectedInscrito(undefined);
                setDisabled(true);
                setSelectedFile(null);
                setLoadingForm(false);

                setShow(false);
              }
            });
          } catch (error: unknown) {
            let responsePromise;
            if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
              toast.error((error as AxiosError)?.response?.data.errores[0], {
                autoClose: 8000, // Duración de 8 segundos
                className: styles.customToast, // Assign the custom class name
              });
            }
            if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
              responsePromise = {
                codigo: (error as AxiosError).response?.data[0].codigo,
                mensaje: (error as AxiosError).response?.data[0].mensaje,
              };
            }

            toast.error(responsePromise, {
              autoClose: 8000, // Duración de 8 segundos
              className: styles.customToast, // Assign the custom class name
            });
          }
        });
      } catch (error) {
        if ((error as AxiosError)?.response?.data && (error as AxiosError).response?.status === 400) {
          toast.error((error as AxiosError)?.response?.data.errores[0], {
            autoClose: 8000, // Duración de 8 segundos
            className: styles.customToast, // Assign the custom class name
          });
        }
        setLoadingForm(false);
      }
    }
  };

  const loadMiembros = async (): Promise<void> => {
    setLoadingForm(true);

    if (selectedFileLoadMiembro) {
      try {
        const addCSV = await MatcherConfiguracionMiembrosInstance.loadCsvConfiguracionMatcherMiembros(
          configuracionDetalleId,
          selectedFileLoadMiembro,
        );
        if (addCSV) {
          const errores = addCSV.estados.map((e) => ({
            codigo: e.linea.toString(),
            mensaje: e.mensaje,
            status: e.status,
          }));

          if (errores.length === 0) {
            setUpdateConfigMiembro('Load miembro');
            toggleSwitch();
          }
          setMensajeErrorLoadInscritos([...mensajeErrorLoadInscritos, ...errores]);
        }
      } catch {
        setLoadingForm(false);
      }
    }
    setLoadingForm(false);
  };
  const getInsncritos = (idComunidad: string) => {
    InscritosServicentance.getInscritosByComunity(idComunidad)
      .then((response) => {
        setCommunitiesEnrolled(response);
      })
      .finally();
  };
  const getAllMiembros = (idConfiguracion: number) => {
    MatcherConfiguracionMiembrosInstance.getAllConfiguracionesMiembros(idConfiguracion).then((response) => {
      setConfiguracionMiembros(response);
      setModificado('Default');
      setDeleteConfigMiembro('Default');
      setUpdateConfigMiembro('Default');
    });
  };

  useEffect(() => {
    getInsncritos(
      selectedComunidadDad === undefined
        ? configuracionDetalle.idComunidad.toString()
        : selectedComunidadDad.id.toString(),
    );
  }, [selectedComunidadDad]);

  useEffect(() => {
    // Si se ha seleccionado inscrito
    let areAllFieldsFilled;
    if (isDomestico) {
      areAllFieldsFilled = Object.values(addPersonalMemberData).every((value) => value !== '');
    } else if (!isDomestico) {
      areAllFieldsFilled = Object.keys(addPersonalMemberData).every((key) => {
        if (key === 'nombre') {
          return true; // No comprueba el nombre
        }
        return addPersonalMemberData[key as keyof typeof addPersonalMemberData] !== '';
      });
    }
    if ((selectedInscrito || areAllFieldsFilled) && (selectedPerfilConsumo || selectedFile || selectedFileLoadMiembro))
      setDisabled(false);
    else if (!isDomestico) setDisabled(true);
  }, [selectedInscrito, selectedPerfilConsumo, addPersonalMemberData, selectedFile, selectedFileLoadMiembro]);

  useEffect(() => {
    getAllMiembros(configuracionDetalleId);
  }, [modifcado, deleteConfigMiembro, updateConfigMiembro]);

  const handleFileSelect = (file: File) => {
    setSelectedFileLoadMiembro(file);
    setDisabled(false);
  };

  const getAllMiembrosComunidades = async () => {
    const response = await MatcherConfiguracionMiembrosInstance.loadMiembrosComunidad(configuracionDetalleId);
    if (response === 200) {
      toggleIsSwitchOnLoadMember();
      getAllMiembros(configuracionDetalleId);
    }
  };
  return (
    <>
      {show ? (
        <ModalMatcher
          title={I18n.t('MatcherListPage.addMember')}
          body={
            <AddMemberPopUp
              communitiesEnrolled={communitiesEnrolled}
              getConfiguracionMiembros={configuracionMiembros}
              addMember={addMember}
              addPotencias={addPotencias}
              addPersonalMemberData={addPersonalMemberData}
              isDomestico={isDomestico}
              selectedPerfilConsumo={selectedPerfilConsumo}
              addConsumoAnual={consumoAnual}
              onUpdateAddMember={setMember}
              onUpdateAddPotencias={setPotencias}
              onUpdateAddPersonalMemberData={setPersonalMemberData}
              onUpdateIsDomestico={setIsDomestico}
              onUpdatePerfilCSV={setSelectedFile}
              onUpdatePerfilSelect={setSelectedPerfilConsumo}
              onUpdateAddConsumoAnual={setconsumoAnual}
              getIdInscritos={setSelectedInscrito}
              mensajeError={mensajeError}
              añoSeleccionado={date.year}
            />
          }
          testbutton={I18n.t('Confirmar')}
          onClick={submitForm}
          onClick2={() => {
            setMember({
              preferente: 'no',
              vip: 'no',
              bMin: 0,
              bMax: 0,
            });
            setPotencias({
              terminoEnergiaP1: 0,
              terminoEnergiaP2: 0,
              terminoEnergiaP3: 0,
              terminoEnergiaP4: 0,
              terminoEnergiaP5: 0,
              terminoEnergiaP6: 0,
              consumPunta: 0,
              consumPla: 0,
              consumVall: 0,
              precioCompensacion: 0,
            });
            setPersonalMemberData({
              nombre: '',
              apellidos: '',
              email: '',
              nif: '',
              telefono: ' ',
              poblacion: '',
              direccion: '',
              domicilio: '',
              provincia: '',
              codigoPostal: '',
              cups: '',
            });
            setIsDomestico({
              domestico: 'domestico',
            });
            setconsumoAnual({
              consumoAnual: 0,
            });
            setSelectedInscrito(undefined);
            setDisabled(true);
            setSelectedFile(null);
            setLoadingForm(false);
            setSelectedPerfilConsumo(undefined);
            setShow(false);
          }}
          disabled={disabled}
          loading={loadingForm}
        />
      ) : null}

      {isSwitchOn ? (
        <ModalMatcher
          title={I18n.t('MatcherListPage.addMember')}
          body={
            <div className={styles.modalCsv}>
              <SectionSubTitle text={I18n.t('MatcherListPage.loadPerfilConsumo')} />

              <ButtonCsv className={styles.csv_button} onFileSelect={handleFileSelect} />
              {mensajeErrorLoadInscritos.length > 0 && (
                <>
                  {mensajeErrorLoadInscritos.map((error) => (
                    <div className={error.status === 'OK' ? styles.errorMessageLoadGood : styles.errorMessageLoad}>
                      <div key={error.codigo}>
                        <p>
                          {I18n.t('MatcherListPage.linea')} {error.codigo}: {error.mensaje.replace(':', ' - ')}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          }
          testbutton={I18n.t('Confirmar')}
          onClick={loadMiembros}
          onClick2={() => {
            toggleSwitch();
            setMensajeErrorLoadInscritos([]);
          }}
          disabled={disabled}
          loading={loadingForm}
        />
      ) : null}

      {isSwitchOnLoadMember ? (
        <ModalMatcher
          title={I18n.t('MatcherListPage.addMember')}
          body={I18n.t('MatcherListPage.loadComunidad')}
          testbutton={I18n.t('Confirmar')}
          onClick={getAllMiembrosComunidades}
          onClick2={() => {
            toggleIsSwitchOnLoadMember();
            setMensajeErrorLoadInscritos([]);
          }}
        />
      ) : null}
      <AddConfiguracion
        configuracionDetalle={configuracionDetalle}
        onLoadComunidades={() => toggleIsSwitchOnLoadMember()}
        onUpdateModal={() => setShow(true)}
        onUpdateModalLoadCSV={() => toggleSwitch()}
        setSelectedComunidadDad={setSelectedComunidadDad}
        communitiesEnrolled={communitiesEnrolled}
        getConfiguracionMiembros={configuracionMiembros}
        onChange={onChange}
        onChangeTable={setDeleteConfigMiembro}
        onChangeUpdate={setUpdateConfigMiembro}
        date={date}
        updateDate={updateDate}
        añoSeleccionado={date.year}
      />
    </>
  );
};

export default MatcherConfig;
