/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import { MockInformeDetalle, MockMatcherTabs } from 'mocks/mock-matcher-list';
import { Spinner, TabsSwitch } from 'components';
import MatcherConfiguracionInstance from 'services/remote/MatcherConfiguracionService';
import { ConfiguracionMatcherAPI } from 'types/models/ConfiguracionMatcher';
import InformeMatcher from './components/informe/Informe';
import styles from './AddMatcherPage.module.scss';
import MatcherConfig from './components/matcherConfig/matcherConfig';

const AddMatcherPage = (props: any): JSX.Element => {
  // Propsgit

  const {
    match: {
      params: { MatcherId },
    },
  } = props;
  // Obtencion de parametro
  const [modifcado, setModificado] = useState('Default');

  const TABS_AVAILABLE = [
    { key: MockMatcherTabs.CONFIG, label: 'Config' },
    { key: MockMatcherTabs.INFORME, label: 'Informe' },
  ];
  const [tabSelected, setTabSelected] = useState(MockMatcherTabs.CONFIG);
  const [configuracionDetalle, setConfiguracionDetalle] = useState<ConfiguracionMatcherAPI>();
  const [loading, setLoading] = useState<boolean>(false);
  // Obtener la tab Selecionada , el usuario no pueda cambiar a informe
  // Add estado de active cuando lance el matcher cambiara la tab a informe
  const handleTabClick = (option: any) => {
    setTabSelected(option.key);
  };

  const getConfiguracionFromId = async (config_id: number) => {
    setLoading(true);
    try {
      const apiData = await MatcherConfiguracionInstance.getConfiguracionMatcherById(config_id);

      setConfiguracionDetalle(apiData);
      setModificado('Default');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    // obtener detalle de configuracion
    if (MatcherId) getConfiguracionFromId(MatcherId);

    setLoading(false);
  }, [MatcherId, modifcado, tabSelected]);

  return (
    <div className={styles.container}>
      <TabsSwitch options={TABS_AVAILABLE} selected={tabSelected} onTabClick={handleTabClick} />

      {loading ? (
        <div className={styles.loading}>
          <Spinner size={100} icon />
        </div>
      ) : (
        <div className={styles.root}>
          {configuracionDetalle !== undefined ? (
            <>
              {/* COMPONENTE ¿?  */}
              {tabSelected === MockMatcherTabs.CONFIG && (
                <MatcherConfig configuracionDetalle={configuracionDetalle} onChange={setModificado} />
              )}
              {/* {tabSelected === MockMatcherTabs.INFORME && activeReport && ( */}
              {tabSelected === MockMatcherTabs.INFORME && (
                <InformeMatcher
                  configuracionId={configuracionDetalle.id}
                  miembrosMatcher={MockInformeDetalle}
                  metodoDeCalculos={configuracionDetalle.metodoDeCalculos}
                />
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AddMatcherPage;
